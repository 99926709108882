import { RouteConfig } from "vue-router";
const Employees = () => import("@/views/admin/employees.vue");
const CreateEmployee = () => import("@/views/admin/create-employee.vue");
const EditEmployee = () => import("@/views/admin/edit-employee.vue");
const EditEmployeeSchedule = () => import("@/views/admin/edit-employee-schedule.vue");
const ManagePtoScheduleTemplates = () => import("@/views/admin/manage-pto-schedule-templates.vue");
const ManageDepartments = () => import("@/views/admin/manage-departments.vue");

export const adminRoutes: RouteConfig[] = [

    {
        name: "Manage Employees",
        path: "/manage/employee",
        component: Employees
    },
    {
        name: "Create Employee",
        path: "/manage/employee/new",
        component: CreateEmployee
    },
    {
        name: "Edit Employee",
        path: "/manage/employee/:employeeId",
        component: EditEmployee
    },
    {
        name: "Edit Employee Schedule",
        path: "/manage/employee/:employeeId/schedule",
        component: EditEmployeeSchedule
    },

    {
        name: "Manage Schedule Templates",
        path: "/manage/pto-schedule-templates",
        component: ManagePtoScheduleTemplates
    },
    {
        name: "Manage Departments",
        path: "/manage/departments",
        component: ManageDepartments
    }
];
