/* eslint-disable import/no-unassigned-import */
import "bootstrap";
import "reflect-metadata";
import "./styles/app.scss";
/* eslint-enable import/no-unassigned-import */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowLeft,
    faArrowRight,
    faChevronDown,
    faChevronRight,
    faCompass,
    faQuestionCircle,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue } from "bootstrap-vue";
import Vue from "vue";
import Component from "vue-class-component";
import App from "./app.vue";
import router from "./router";
import store from "./store";
import { AuthService } from "@/services/auth";

async function start() {
    Vue.use(BootstrapVue);
    Vue.component("font-awesome-icon", FontAwesomeIcon);
    Vue.config.productionTip = false;

    Component.registerHooks([
        "beforeRouteEnter",
        "beforeRouteLeave",
        "beforeRouteUpdate"
    ]);

    library.add(
        faArrowLeft,
        faArrowRight,
        faChevronDown,
        faChevronRight,
        faCompass,
        faQuestionCircle,
        faTimes
    );

    await AuthService.initialize();

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount("#app");
}

start();
