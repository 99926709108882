
import { Api } from "shared/api";
import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/nav-bar.vue";
import { AuthService } from "@/services/auth";

@Component({
    components: {
        NavBar
    }
})
export default class App extends Vue {
    private initializing = true;
    private initializationText = "We are initializing the connection to the server.";

    private async mounted() {
        await AuthService.initialize();
        try {
            await Api.Get.raw();
            this.initializing = false;
        } catch (e) {
        // eslint-disable-next-line no-console
            console.debug(e);
            this.initializationText = "Something went wrong contacting the server. " +
                "Please wait a few moments, refresh, and then try again. " +
                "If the problem persists, call Fuse Technology Group @ (248) 545-0800";
        }
    }
}
