import { Api } from "shared/api";
import { IEmployee } from "shared/entities/employee";
import { RawLocation } from "vue-router";

export class AuthService {
    private static _cachedEmployee: IEmployee|null = null;

    static loginUrl(): RawLocation {
        return localStorage.getItem("loginUrl") ?? { name: "Login" };
    }

    static setLoginUrl(loginUrl: string) {
        localStorage.setItem("loginUrl", loginUrl);
    }

    static getEmployee() {
        return this._cachedEmployee;
    }

    static async initialize() {
        try {
            const response = await Api.auth.Self.Get();
            if (response.success) {
                this._cachedEmployee = response.result;
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    static clear() {
        this._cachedEmployee = null;
        localStorage.removeItem("loginUrl");
    }
}
