import { RouteConfig } from "vue-router";
const ManagePtoRequests = () => import("@/views/pto/manage-pto-requests.vue");
const ManagePto = () => import("@/views/pto/manage-pto.vue");
const MakePtoRequest = () => import("@/views/pto/make-pto-request.vue");
const PtoHistory = () => import("@/views/pto/pto-history.vue");
const VacationCalendar = () => import("@/views/pto/vacation-calendar.vue");

export const ptoRoutes: RouteConfig[] = [
    {
        name: "Manage PTO",
        path: "/pto/manage",
        component: ManagePto
    },
    {
        name: "Manage Requests",
        path: "/pto/requests/manage",
        component: ManagePtoRequests
    },
    {
        name: "Request PTO",
        path: "/pto/request",
        component: MakePtoRequest
    },
    {
        name: "PTO History",
        path: "/pto/history",
        component: PtoHistory
    },
    {
        name: "Vacation Calendar",
        path: "/pto/calendar",
        component: VacationCalendar
    }
];
