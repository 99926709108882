import { RouteConfig } from "vue-router";
const Login = () => import("@/views/login.vue");
const LoginFromEmail = () => import("@/views/login-from-email.vue");
const LoginAs = () => import("@/views/login-as.vue");
const RequestPasswordReset = () => import("@/views/request-password-reset.vue");
const ResetPassword = () => import("@/views/reset-password.vue");

export const authRoutes: RouteConfig[] = [
    {
        name: "Cross Site Authentication",
        path: "/login-as/:authToken",
        component: LoginAs
    },
    {
        name: "Login",
        path: "/login",
        component: Login
    },
    {
        name: "Login by Email",
        path: "/login/:token",
        component: LoginFromEmail
    },
    {
        name: "Request Password Reset",
        path: "/reset-password",
        component: RequestPasswordReset
    },
    {
        name: "Reset Password",
        path: "/reset-password/:token",
        component: ResetPassword
    }
];
