

import {
    IEmployee
} from "shared/entities/employee";
import { Permission } from "shared/entities/permission";
import { Component, Mixins, Watch } from "vue-property-decorator";
import EmployeeMixin from "@/mixins/employee-mixin";

interface LinkDef {
    name: string;
    routeName: string;
    permissions: Permission[];
}

interface DropdownLinkDef {
    name: string;
    subLinks: Omit<LinkDef, "subLinks">[];
    permissions: Permission[];
}

@Component({})
export default class NavBar extends Mixins(EmployeeMixin) {
    private employee: IEmployee|null = null;

    private links: (LinkDef | DropdownLinkDef)[] = [
        { name: "Home", routeName: "Home", permissions: [Permission.usage.basic] },
        { name: "Request PTO", routeName: "Request PTO", permissions: [Permission.usage.pto, Permission.manage.pto.requests] },
        { name: "PTO History", routeName: "PTO History", permissions: [Permission.usage.pto, Permission.manage.pto.entries] },
        { name: "Vacation Calendar", routeName: "Vacation Calendar", permissions: [Permission.usage.pto, Permission.manage.pto.requests] },
        {
            name: "Manage",
            permissions: Permission.manage.allContained(),
            subLinks: [
                { name: "Requests", routeName: "Manage Requests", permissions: [Permission.manage.pto.requests] },
                { name: "PTO", routeName: "Manage PTO", permissions: [Permission.manage.pto.entries] }]
        },
        {
            name: "Admin",
            permissions: [...Permission.manage.allContained(), ...Permission.admin.allContained()],
            subLinks: [
                { name: "Employees", routeName: "Manage Employees", permissions: [Permission.manage.employee, Permission.admin.employee] },
                { name: "Departments", routeName: "Manage Departments", permissions: [Permission.admin.departments] },
                { name: "Schedule Templates", routeName: "Manage Schedule Templates", permissions: [Permission.admin.scheduleTemplates] }]
        }
    ];

    private get currentPage() {
        return this.$route.name;
    }

    private visibleLinks(links: (LinkDef | DropdownLinkDef)[]): (LinkDef | DropdownLinkDef)[] {
        if (this.employee !== null)
            return [
                ...links.filter(l => l.permissions.length === 0),
                ...links.filter(l => l.permissions.reduce((p: boolean, c) => p || c.includedIn(this.employee!.permissions), false))
            ];
        return [];
    }

    private isCurrent(def: (LinkDef | DropdownLinkDef)): boolean {
        if (typeof this.currentPage !== "string")
            return false;

        if ("routeName" in def && def.routeName === this.currentPage)
            return true;

        return "subLinks" in def && def.subLinks.map(sl => sl.routeName).includes(this.currentPage);
    }

    async mounted() {
        await this.getEmployee();
    }

    @Watch("$route")
    private async onPathChange() {
        await this.getEmployee();
        this.$forceUpdate();
    }

    private async getEmployee() {
        if (await this.authorized())
            this.employee = await this.getAuthenticatedEmployee();
        else
            this.employee = null;
    }

    private async logout() {
        await this.deauthorize();
        await this.redirectToLogin();
    }
}

