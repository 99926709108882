import { ApiResponse } from "shared/api/response";
import router from "@/router";
import { AuthService } from "@/services/auth";

export async function callApi<T>(apiEndPoint: () => Promise<ApiResponse<T, string>>): Promise<T>;
export async function callApi<T, TT>(apiEndPoint: (arg1: TT) => Promise<ApiResponse<T, string>>, arg1: TT): Promise<T>;
export async function callApi<T, TT, TTT>(apiEndPoint: (arg1: TT, arg2: TTT) => Promise<ApiResponse<T, string>>, arg1: TT, arg2: TTT): Promise<T>;
export async function callApi<T, TT = unknown, TTT = unknown>(apiEndPoint: (arg1?: TT, arg2?: TTT) => Promise<ApiResponse<T, string>>, arg1?: TT, arg2?: TTT): Promise<T> {
    try {
        let response: ApiResponse<T, string>;

        if (arg2 !== undefined)
            response = await apiEndPoint(arg1, arg2);
        else if (arg1 !== undefined)
            response = await apiEndPoint(arg1);
        else
            response = await apiEndPoint();

        if (response.success)
            return response.result;

        alert(response.error);
    } catch (e) {
        if (e instanceof Response) {
            switch (e.status) {
            case 400:
                {
                    const response = await e.json();
                    alert(response.message.join("\n"));
                } break;
            case 403:
                {
                    const response = await e.text();
                    if (response === "{\"statusCode\":403,\"message\":\"Forbidden resource\",\"error\":\"Forbidden\"}") {
                        alert("Authentication expired");
                        AuthService.clear();
                        const url = AuthService.loginUrl();
                        if (url === "string")
                            window.location.assign(url);
                        else
                            router.push(url);
                    } else {
                        alert("Authorization Error" + response);
                    }
                }
                break;
            case 404:
                alert("The requested item was not found.");
                break;
            default:
                alert("There was an unknown internal api error.");
            }
            throw e;
        } else {
            alert("There was an unknown internal app error.");
            throw e;
        }
    }
    throw new Error("Api Failure");
}
