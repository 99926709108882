import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { adminRoutes } from "@/router/admin";
import { authRoutes } from "@/router/auth";
import { ptoRoutes } from "@/router/pto";
const Home = () => import("@/views/home.vue");

const homeRoute: RouteConfig = {
    path: "",
    name: "Home",
    component: Home
};

const notFoundRoute: RouteConfig = {
    name: "Page Not Found",
    path: "*",
    component: {
        render(createElement: any) {
            const node = createElement("div");
            node.text = "Page not found";
            node.data = { style: "text-align: center" };
            return node;
        }
    }
};

const routes: RouteConfig[] = [];
routes.push(homeRoute);
authRoutes.map(route => routes.push(route));
ptoRoutes.map(route => routes.push(route));
adminRoutes.map(route => routes.push(route));
routes.push(notFoundRoute);

Vue.use(VueRouter);
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});
export default router;
