import { Api } from "shared/api";
import {
    IEmployee
} from "shared/entities/employee";
import { Permission } from "shared/entities/permission";
import { Component, Vue } from "vue-property-decorator";
import { Location } from "vue-router";
import { AuthService } from "@/services/auth";
import { callApi } from "@/utilities/api-helper";

@Component
export default class EmployeeMixin extends Vue {
    async redirectToLogin() {
        const url = AuthService.loginUrl();
        if (typeof url === "object")
            await this.$router.push(AuthService.loginUrl());
        else
            window.location.assign(url);
    }

    getAuthenticatedEmployee(): IEmployee {
        const employee = AuthService.getEmployee();
        if (!employee)
            throw new Error("There is no employee");
        return employee;
    }

    async ensureEmployeeCan(permissions: Permission|Permission[], redirectTo: Location = { name: "Home" }): Promise<boolean> {
        if (!this.authorized()) {
            await this.redirectToLogin();
            return false;
        }
        if (!this.employeeCanAny(permissions)) {
            await this.$router.push(redirectTo);
            return false;
        }
        return true;
    }

    employeeCanAny(permissions: Permission|Permission[]): boolean {
        const employee = this.getAuthenticatedEmployee();
        if (!employee)
            return false;
        if (!Array.isArray(permissions))
            permissions = [permissions];
        for (const permission of permissions)
            if (permission.includedIn(employee.permissions))
                return true;

        return false;
    }

    employeeCanAll(permissions: Permission|Permission[]): boolean {
        const employee = this.getAuthenticatedEmployee();
        if (!employee)
            return false;
        if (!Array.isArray(permissions))
            permissions = [permissions];
        for (const permission of permissions)
            if (!permission.includedIn(employee.permissions))
                return false;

        return true;
    }

    authorized(): boolean {
        return AuthService.getEmployee() !== null;
    }

    async deauthorize() {
        AuthService.clear();
        await callApi(Api.auth.Login.Delete);
    }
}
